/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-12-05",
    versionChannel: "nightly",
    buildDate: "2024-12-05T00:06:32.266Z",
    commitHash: "44ebce3b18ea0a747331ac7748e8038a030df1a2",
};
